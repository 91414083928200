<template>
    <div class="container-fluid view vacancies">
        <div class="sidebar-view">
            <left-sidebar-component @search="getVacancies" :search="search" />
        </div>
        <div class="content-view">

            <div class="d-flex justify-content-between">
                <div class="title">
                    <h1>{{ $t("Vacancies") }}</h1>
                </div>
                <div class="buttons">
                    <router-link v-if="['Client','Management'].includes(userGroup) && can('can_view_vacancies')" :to="{name: 'MyVacancies'}" tag="button" class="btn btn-success me-3">
                        My Vacancies
                    </router-link>
                    <router-link v-if="['Client','Management'].includes(userGroup)" :to="{name: 'AddVacancy'}" tag="button" class="btn btn-primary">
                        Post a Job
                    </router-link>
                </div>
            </div>

            <div class="top-search">
                <div class="form-group icon-input wide-input">
                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20.155" height="20.176" viewBox="0 0 20.155 20.176">
                        <path d="M30.576 29.224l-3.73-3.73a8.9 8.9 0 1 0-1.427 1.452l3.73 3.73a.988.988 0 0 0 .7.3.909.909 0 0 0 .7-.3 1.052 1.052 0 0 0 .027-1.452zm-3.755-9.387a7.008 7.008 0 1 1-2.053-4.982 7.005 7.005 0 0 1 2.053 4.982z" transform="translate(-10.7 -10.8)"/>
                    </svg>
                    <input type="text" class="form-control" v-model.trim="search.q" :placeholder="$t('Search')">
                </div>
                <div class="form-group">
                    <input v-model.trim="search.where" class="form-control-rounded" type="text" :placeholder="$t('Search by location')">
                </div>
                <div class="form-group icon-input multiselect-input align-right">
                    <Multiselect
                        v-model="sortBy"
                        :options="sortList"
                        @select="getVacancies"
                        :placeholder="$t('Sort by')"
                        class="form-control"
                    />
                </div>
            </div>

            <vacancy-list-component v-for="vacancy in vacancies" :key="vacancy.id"
                                    :vacancy="vacancy"
                                    @quickApply="quickApply(vacancy)"
                                    @applyNow="applyNow(vacancy)"
            />

            <button class="btn btn-secondary btn-secondary-smaller load-more">{{ $t('Load More') }}</button>
            
        </div>
    </div>

    <quick-apply-component v-if="showQuickApplyComponent"
                                   @hideComponent="showQuickApplyComponent=false"
                                   :vacancy="applyVacancy"
    />
    <apply-now-component v-if="showApplyNowComponent"
                                 @hideComponent="showApplyNowComponent=false"
                                 :vacancy="applyVacancy"
    />
</template>

<script>
import {vacancyService} from "@/services/vacancyService";
import VacancyListComponent from "@/components/vacancies/VacancyListComponent"
import QuickApplyComponent from "@/components/vacancies/QuickApplyComponent";
import ApplyNowComponent from "@/components/vacancies/ApplyNowComponent";
import LeftSidebarComponent from "@/components/vacancies/LeftSidebarComponent";
import Multiselect from '@vueform/multiselect'

export default {
    name: "Vacancies",
    data() {
        return {
            filters: [],
            page: this.$route.query.page,
            sortBy: '',
            sortDir: '',
            showQuickApplyComponent: false,
            showApplyNowComponent: false,
            applyVacancy: {},
            search: {
                q: this.$route.query.q,
                category: this.$route.query.category,
                industry: this.$route.query.industry ? this.$route.query.industry.split(',') : [],
                employment: this.$route.query.employment ? this.$route.query.employment.split(',') : [],
                where: this.$route.query.where,
                salary_min: this.$route.query.salary_min,
                salary_max: this.$route.query.salary_max,
            },
        }
    },
    created() {
        this.sortBy = this.getSortBy()
        this.sortDir = this.getDirection() || 'asc'
    },
    computed: {
        vacancies() {
            return this.$store.state.vacancies.vacancies
        },
        params() {
            return ''
                + (this.sortBy ? `&${this.sortBy}=${this.sortDir}` : '')
                + (this.page ? `&page=${this.page}` : '')
        },
        url() {
            const reducer = (accumulator, currentValue) =>
                accumulator += currentValue[1] !== undefined
                    ? (Array.isArray(currentValue[1]) && currentValue[1].length === 0 ? '' : currentValue.join('=') + '&')
                    : '';

            return '/vacancies?'
                + (Object.entries(this.search).reduce(reducer, '').slice(0, -1))
                + this.params
                + (this.filters.length
                    ? '&' + this.filters.join('&')
                    : '')
        },
        userGroup() {
            return this.$store.getters.user_type
        },
        sortList() {
            return [
                {
                    label: this.$t('Create Date'),
                    value: 'create_date'
                },
                {
                    label: this.$t('Update Date'),
                    value: 'update_date'
                },
                {
                    label: this.$t('Salary'),
                    value: 'salary'
                }
            ];
        }
    },
    watch: {
        'search': {
            handler: function (after, before) {
                this.getVacancies()
            },
            deep: true
        },
        sortDir() {
            this.getVacancies()
        },
        page() {
            this.getVacancies()
        },
        $route(v) {
            if (this.$route.name === "Vacancies" && Object.keys(v.query).length === 0) {
                this.search = {
                    employment: [],
                }
                this.sortBy = ''
                this.filters = []
                this.page = null
                this.getVacancies()
            }
        }
    },
    methods: {
        getVacancies() {
            this.$router.replace(this.url)
            vacancyService.index(this.url).then(response => {
                this.$store.commit('setVacancies', response.data.data)
            })
        },
        getSortBy() {
            return Object.keys(this.$route.query).find(key => Object.keys(this.sortList).includes(key))
        },
        getDirection() {
            return this.$route.query[this.sortBy]
        },
        quickApply(vacancy) {
            this.applyVacancy = vacancy
            this.showQuickApplyComponent = true
        },
        applyNow(vacancy) {
            this.applyVacancy = vacancy
            this.showApplyNowComponent = true
        }
    },
    components: {
        VacancyListComponent,
        QuickApplyComponent,
        ApplyNowComponent,
        LeftSidebarComponent,
        Multiselect
    }
}
</script>